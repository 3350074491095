import React, { useEffect, useState } from "react";
import { Link as Link2 } from 'react-router-dom';
import client from "../client.js"

export default function Blog() {

  const [postData, setPostData] = useState([]);


  useEffect(() => {
    client.fetch(
      `*[_type == "post"]{
        title,
        slug,
        _id,
        mainImage{
          asset->{
          _id,
          url
        }
      },
      body
    }`
      )
      .then((data) => setPostData(data))
      .catch(console.error);
  }, [])

  const getShortDescription = (body) => {
    const plainText = body
      .filter(block => block._type === 'block')
      .map(block => block.children.map(child => child.text).join(''))
      .join(' ');

    return plainText.length > 200 ? plainText.substring(0, 200) + '...' : plainText;
  };

  return (
    <>
      <section className="relative md:py-24 py-16" id="blog">
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-orange-600 text-base font-medium uppercase mb-2">Blogs</h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Latest News</h3>

            <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Launch your campaign and benefit from our expertise on designing and managing conversion centered Tailwind CSS html page.</p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6 mt-8">
            {postData.map((post) => (
              <div key={post._id} className="blog relative rounded-md shadow shadow-slate-200 dark:shadow-slate-800 overflow-hidden">
                <img src={post.mainImage.asset.url} alt="" />
                <div className="content p-6">
                  <Link2 to={`/blog-detail/${post.slug.current}`} key={post.slug.current} className="text-lg hover:text-orange-600 dark:text-white dark:hover:text-orange-600 transition-all duration-500 ease-in-out font-medium">{post.title}</Link2>
                  <p className="text-slate-400 mt-3">{getShortDescription(post.body)}</p>

                  <div className="mt-5">
                    <Link2 to={`/blog-detail/${post.slug.current}`} key={post.slug.current} className="btn btn-link hover:text-orange-600 dark:hover:text-orange-600 after:bg-orange-600 dark:text-white transition duration-500">Read More <i className="uil uil-arrow-right"></i></Link2>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );

}

